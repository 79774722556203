export function scroll() {
  $('a[href^="#"]').click(function () {
    let href = $(this).attr('href');
    let target = $(href == "#" || href == "" ? 'html' : href);
    let headerH = $('.l-header').height();
    let position = target.offset().top - headerH - 20;
    let speed = 500;
    $('html, body').animate({
      scrollTop: position
    }, speed, 'swing');
    return false;
  });  
}
  