export function fade() {
  $(window).on('load scroll', function () {
    const windowHeight = $(window).height(),
          scroll = $(window).scrollTop(),
          fade = $('.c-fade')

    // ここから追加
    let buffer;
    let sp = window.matchMedia('(max-width: 767px)').matches;
    if (sp) {
      buffer = 100;
    } else {
      buffer = 200;
    }
    // ここまで追加
    
    fade.each(function () {
      const targetPosition = $(this).offset().top;
      if (scroll > targetPosition - windowHeight + buffer) {
        $(this).addClass('c-fadeIn');
      }
    });
  });

}
  