export function accr() {
  let accrBtn = $('.c-accr')
      
  accrBtn.on('click', function(){
    let hasClose = $(this).hasClass('is-close'),
        hasOpen = $(this).hasClass('is-open');
    if(hasClose) {
      $(this).find('.c-accr_cont').slideDown();
      $(this).find('.c-accrLists_itemArrowSwich').addClass('is-up');
      $(this).removeClass('is-close').addClass('is-open');
    } else if(hasOpen){
      $(this).find('.c-accr_cont').slideUp();
      $(this).find('.c-accrLists_itemArrowSwich').removeClass('is-up');
      $(this).removeClass('is-open').addClass('is-close');
    }
    $(window).on('resize', function(){
      $('.c-accr_cont').slideUp();
      $('.c-accrLists_itemArrowSwich').removeClass('is-up');
      accrBtn.removeClass('is-open').addClass('is-close');
    })
  });
}
  