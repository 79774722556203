export function changeImg() {
  
  function ggg(){
    let mediaSP = window.matchMedia('(max-width: 767px)').matches;
    let mediaPC = window.matchMedia('(min-width: 768px)').matches;
    function changeImg(bfr,aft){
      $('.resChangeImg').each(function(index, element) {
        let replaceImg = $(this).attr('src').replace(bfr,aft);
        $(this).attr('src', replaceImg);
      })
    }

    if (mediaSP) {
      changeImg('_pc','_sp');      
    } else if (mediaPC) {
      changeImg('_sp','_pc');
    }
  }

  ggg();

  $(window).on('resize', function(){
    ggg();
  });
}
  