export function bnrSlids() {
  let bnrsMenuSlide = $('.c-bnrsMenuSlide'),
      bnrsOtherSlide = $('.c-bnrsOtherSlide');
      function sliderSetting(){
        let sp = window.matchMedia('(max-width: 767px)').matches;
        if(sp){
          bnrsMenuSlide.not('.slick-initialized').slick({
              dots: true,
              arrows: false,
              slidesToShow: 1,
              appendDots: $('.c-bnrsMenuSlideDots')
          });
          bnrsOtherSlide.not('.slick-initialized').slick({
            dots: true,
            arrows: false,
            slidesToShow: 1,
            appendDots: $('.c-bnrsOtherSlideDots')
        });
        } else {
          $('.c-bnrs.slick-initialized').slick('unslick');
        }
      }
  
      $(window).resize( function() {
          sliderSetting();
      });
      sliderSetting();

}
  