export function header() {


  let spMenuBtn = $('#js-headerMenuBtn'),
      spMenu = $('.l-headerSpnavMenu'),
      spMenuBtnCls = $('#js-headerMenuBtnCls'),
      spChildMenuBtn = $('.l-headerSpMenuLists_state');
  spMenuBtn.on('click', function(){
    let nowClose = $(this).hasClass('is-close');
    let nowOpen = $(this).hasClass('is-open');       
    if(nowClose){
      $(this).removeClass('is-close');
      $(this).addClass('is-open');
      spMenu.fadeIn();
    } else if(nowOpen){
      $(this).removeClass('is-open');
      $(this).addClass('is-close');
    }
  });
  spChildMenuBtn.on('click', function(){
    let plus = 'l-headerSpMenuLists_state--plus',
        minus = 'l-headerSpMenuLists_state--minus',
        spMenuTtl = $('.l-headerSpMenuLists_ttl'),
        spMenuChild = $('.l-headerSpMenuListsChildWrap'),
        nowPlus = $(this).hasClass('l-headerSpMenuLists_state--plus'),
        nowMinus = $(this).hasClass('l-headerSpMenuLists_state--minus');    
    if(nowPlus){
      $('.l-headerSpMenuLists_state').addClass(plus);
      $('.l-headerSpMenuLists_state').removeClass(minus);
      $(this).addClass(minus);
      $(this).removeClass(plus);
      spMenuChild.slideUp();
      $(this).parent(spMenuTtl).next(spMenuChild).slideDown();
    } else if(nowMinus){
      $(this).addClass(plus);
      $(this).removeClass(minus);
      $(this).parent(spMenuTtl).next(spMenuChild).slideUp();
    }
  })
  spMenuBtnCls.on('click', function(){
    spMenu.fadeOut();
    spMenuBtn.addClass('is-close');
    spMenuBtn.removeClass('is-open');
  });
  $(window).on('resize', function(){
    let pc = window.matchMedia('(min-width: 768px)').matches;
    if(pc){
      spMenuBtnCls.trigger('click');
    }
  });
}
  