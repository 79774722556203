import { header } from './modules/header';
import { bnrSlids } from './modules/bnrslids';
import { fade } from './modules/fade';
import { scroll } from './modules/scroll';
import { accr } from './modules/accr';
import { changeImg } from './modules/changeImg';
import { lazyload } from './modules/lazyload';
import { bread } from './modules/bread';
header();
bnrSlids();
fade();
scroll();
accr();
changeImg();
lazyload();
bread();