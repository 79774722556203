export function lazyload() {
  
  document.addEventListener("DOMContentLoaded", function() {
 
    // 遅延読み込みの画像(.lazy)を取得
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazyload"));
   
    // Intersection Observerに対応しているかどうか
    if("IntersectionObserver" in window) {
      // Intersection Observerのオブジェクトを生成
      var lazyImageObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          // 対象画像がビューポートに入った時
          if (entry.isIntersecting) {
            var lazyImage = entry.target;
            // 対象画像のsrcをdata-srcの内容に変更
            lazyImage.src = lazyImage.dataset.src;
            // 対象画像の.lazyを削除
            lazyImage.classList.remove("lazyload");
            // 対象画像を監視から外す
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });
   
      // 遅延読み込みの各画像の監視を実行
      lazyImages.forEach(function(lazyImage) {
        lazyImageObserver.observe(lazyImage);
      });
   
    // Intersection Observerに対応していない場合
    } else {
      // 対象画像のsrcをdata-srcの内容に変更
      for (var i = 0; i < lazyImages.length; i++) {
        lazyImages[i].src = lazyImages[i].dataset.src;
      }
    }
  });

}
  